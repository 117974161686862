$grey: #5b5b5f;
$blue: #3268e0;
$light: #6d9bf7;

* {
  padding: 0;
  margin: 0;
}

html,
body {
  overflow-x: hidden;
}

body {
  font-family: 'Lato', sans-serif;
  color: $grey;

  @media all and (max-width: 600px) {
    font-size: 0.8em;
  }
}

img {
  user-drag: none;
  -webkit-user-drag: none;
}

.full-width {
  width: 100%;
}

.title {
  color: $blue;
  font-size: 3em;
  font-weight: bold;
}

.subtitle {
  font-size: 2.4em;
  font-weight: bold;
}

.paragraph {
  font-size: 1.2em;
}

.button {
  padding: 20px 30px;
  border-radius: 14px;
  font-size: 1.2em;
  text-decoration: none;
  color: #fff;

  &__whatsapp {
    background-color: #25d366;
  }
}

.text-center {
  text-align: center;
}

.margin-top {
  margin-top: 20px !important;
  font-size: 1.1em;
}