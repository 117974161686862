* {
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
}

body {
  color: #5b5b5f;
  font-family: Lato, sans-serif;
}

@media (width <= 600px) {
  body {
    font-size: .8em;
  }
}

img {
  user-drag: none;
  -webkit-user-drag: none;
}

.full-width {
  width: 100%;
}

.title {
  color: #3268e0;
  font-size: 3em;
  font-weight: bold;
}

.subtitle {
  font-size: 2.4em;
  font-weight: bold;
}

.paragraph {
  font-size: 1.2em;
}

.button {
  color: #fff;
  border-radius: 14px;
  padding: 20px 30px;
  font-size: 1.2em;
  text-decoration: none;
}

.button__whatsapp {
  background-color: #25d366;
}

.text-center {
  text-align: center;
}

.margin-top {
  font-size: 1.1em;
  margin-top: 20px !important;
}

.header {
  justify-content: space-between;
  align-items: center;
  padding: 20px 60px;
  display: flex;
}

@media (width <= 600px) {
  .header {
    flex-direction: column;
  }
}

.logo__image {
  width: 385px;
}

@media (width <= 600px) {
  .logo__image {
    width: 100%;
  }
}

.menu {
  font-size: 1.2em;
  list-style: none;
  display: flex;
}

@media (width <= 600px) {
  .menu {
    margin-top: 20px;
    font-size: 1.3em;
  }
}

.menu__item {
  margin: 0 10px;
}

.menu__item a {
  color: #5b5b5f;
  text-decoration: none;
}

.menu__item:last-child {
  margin-right: 0;
}

.menu__item--active a {
  color: #3268e0;
  font-weight: bold;
}

.slider {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  display: flex;
  position: relative;
}

@media (width <= 680px) {
  .slider--services {
    height: 500px;
  }
}

.slider:before {
  content: "";
  z-index: 1;
  background: linear-gradient(#fff9, #fff9);
  position: absolute;
  inset: 0;
}

.slider__image {
  object-fit: cover;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.slider__information {
  text-align: center;
  z-index: 1;
  visibility: hidden;
  width: 53%;
  position: absolute;
}

@media (width <= 600px) {
  .slider__information {
    width: 80%;
  }
}

.slider__description {
  color: #3268e0;
  margin: 20px 0;
}

.about-us {
  gap: 60px;
  padding: 160px;
  display: flex;
}

.about-us > div {
  flex: 1;
}

.about-us > div:first-child {
  flex: 1.4;
}

.about-us__subtitle {
  color: #3268e0;
}

.about-us__paragraph {
  color: #5b5b5f;
  margin: 10px 0;
  line-height: 1.6em;
}

.about-us__experience {
  margin-top: 20px;
  padding-top: 80px;
  list-style: none;
}

.about-us__experience--page {
  padding-top: 0;
}

.about-us__experience li {
  margin-bottom: 10px;
  padding-left: 30px;
  font-size: 1.2em;
  position: relative;
}

.about-us__experience li:before {
  content: "";
  background-image: url("check.c13be04f.svg");
  width: 15px;
  height: 15px;
  position: absolute;
  top: 5px;
  left: 0;
}

.about-us--background {
  background: #efefef;
  position: relative;
}

.about-us--background > div {
  z-index: 2;
}

.about-us--background:before {
  content: "";
  z-index: 1;
  background-image: url("h.287349d7.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 600px) {
  .about-us--background:before {
    background-size: cover;
  }
}

.about-us--page {
  align-items: center;
  padding: 120px;
}

.about-us--page > div {
  flex: 1 !important;
}

.about-us--page .about-us__subtitle {
  margin-bottom: 40px;
}

@media (width <= 980px) {
  .about-us {
    gap: 30px;
    padding: 160px 60px;
  }
}

@media (width <= 820px) {
  .about-us {
    flex-direction: column;
  }

  .about-us__experience {
    padding-top: 0;
  }
}

@media (width <= 600px) {
  .about-us {
    padding: 60px 30px;
  }
}

.services {
  background-color: #3268e0;
  border-top: 1px solid #dcdcdc;
  padding: 100px 60px;
}

@media (width <= 600px) {
  .services {
    padding: 60px 30px;
  }
}

.services h2 {
  text-align: center;
  color: #fff;
}

.services p {
  color: #fff;
  text-align: center;
  margin-top: 15px;
}

.services__grid {
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  margin-top: 60px;
  display: grid;
}

@media (width <= 600px) {
  .services__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.services__item {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #6d9bf7;
  border-radius: 13px;
  flex-direction: column;
  justify-content: center;
  padding: 35px;
  text-decoration: none;
  display: flex;
}

.services__item:hover img {
  transform: scale(1.2);
}

.services__item img {
  width: 45px;
  transition: all .3s;
}

.services__item--mouse img {
  width: 60px;
}

.services__item h3 {
  color: #3268e0;
  width: 80%;
  margin-top: 20px;
  font-size: 1.1em;
  font-weight: bold;
}

.contact {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.contact--reverse {
  flex-direction: row-reverse;
}

.contact__cover {
  width: 50%;
  height: 700px;
}

.contact__cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.contact__details {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 0 20px;
  display: flex;
}

.contact__details h2 {
  color: #3268e0;
  text-align: center;
  font-size: 2em;
}

.contact__button {
  margin-top: 60px;
  background-color: #148541 !important;
}

.contact__paragraph {
  margin-top: 10px;
  font-size: .9em;
}

@media (width <= 820px) {
  .contact {
    flex-direction: column-reverse;
  }

  .contact__cover {
    width: 100%;
    height: 300px;
  }

  .contact__details {
    width: 100%;
    padding: 100px 60px;
  }
}

.services__details .service {
  height: 700px;
  display: flex;
  position: relative;
}

.services__details .service:after {
  content: "";
  z-index: 9;
  background-image: url("h.287349d7.svg");
  background-size: contain;
  width: 65px;
  height: 63px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.services__details .service:nth-child(2n) {
  flex-direction: row-reverse;
}

.services__details .service:nth-child(2n):after {
  left: auto;
  right: 20px;
}

@media (width <= 980px) {
  .services__details .service {
    height: 600px;
  }
}

@media (width <= 680px) {
  .services__details .service {
    flex-direction: column;
    height: auto;
  }

  .services__details .service:nth-child(2n) {
    flex-direction: column;
  }

  .services__details .service:last-child {
    border-bottom: 1px dashed #eee;
  }
}

.services__details .service__list {
  box-sizing: border-box;
  margin-top: 20px;
  padding-left: 24px;
  font-size: 1.2em;
  list-style-position: inside;
}

.services__details .service__list li {
  margin-bottom: 10px;
}

.services__details .service__subtitle {
  color: #3268e0;
  margin-bottom: 20px;
}

@media (width <= 820px) {
  .services__details .service__subtitle {
    font-size: 2em;
  }
}

.services__details .service__paragraph:nth-of-type(2) {
  color: #3268e0;
  margin-top: 15px;
  font-size: 1em;
}

.services__details .service__image {
  object-fit: cover;
  width: 50%;
  height: 100%;
}

@media (width <= 680px) {
  .services__details .service__image {
    width: 100%;
    height: 300px;
  }
}

.services__details .service__info {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 100px 60px;
  display: flex;
}

@media (width <= 980px) {
  .services__details .service__info {
    padding: 60px 40px;
  }
}

@media (width <= 680px) {
  .services__details .service__info {
    width: 100%;
  }
}

.footer {
  background-color: #1d2a4d;
  gap: 40px;
  padding: 60px 160px;
  display: flex;
}

@media (width <= 980px) {
  .footer {
    flex-direction: column;
    padding: 60px 30px;
  }
}

.footer__column {
  flex: 1;
}

.footer__column--full-width {
  flex: 2;
}

.footer__logo {
  width: 300px;
}

@media (width <= 600px) {
  .footer__logo {
    width: 100%;
  }
}

.footer__description {
  color: #fff;
  margin-top: 15px;
  font-size: 1em;
  font-weight: 400;
}

.footer__copyright {
  color: #fff;
  margin-top: 15px;
  font-size: .8em;
}

.footer__subtitle {
  color: #fff;
  margin-top: 30px;
  font-size: 1.2em;
}

.footer__links {
  margin-top: 20px;
}

@media (width <= 600px) {
  .footer__links {
    list-style-position: inside;
  }
}

.footer__links li {
  color: #fff;
  margin-bottom: 10px;
}

.footer__links li::marker {
  color: #fff;
}

.footer__links li a {
  color: #fff;
  text-decoration: none;
  transition: all .5s;
}

.footer__links li a:hover {
  color: color(srgb .97 .93 .93 / .66);
}

.button--about-us {
  background: #3268e0;
  width: fit-content;
  margin-top: 20px;
  font-size: 1em;
  display: block;
}

.button--floating {
  z-index: 10;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  padding: 0;
  display: flex;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.button--floating-phone {
  display: none;
  left: 15px;
  right: auto;
}

@media (width <= 600px) {
  .button--floating-phone {
    display: flex;
  }
}

.button--floating img {
  width: 30px;
}
/*# sourceMappingURL=index.82d7e907.css.map */
