@import './default.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px;

  @media all and (max-width: 600px) {
    flex-direction: column;
  }
}

.logo {
  &__image {
    width: 385px;

    @media all and (max-width: 600px) {
      width: 100%;
    }
  }
}

.menu {
  display: flex;
  list-style: none;
  font-size: 1.2em;

  @media all and (max-width: 600px) {
    margin-top: 20px;
    font-size: 1.3em;
  }

  &__item {
    margin: 0 10px;

    a {
      color: $grey;
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0;
    }

    &--active {
      a {
        color: $blue;
        font-weight: bold;
      }
    }
  }
}

.slider {
  height: 600px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &--services {
    @media all and (max-width: 680px) {
      height: 500px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    );
    z-index: 1;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    visibility: hidden;
  }

  &__information {
    position: absolute;
    text-align: center;
    z-index: 1;
    width: 53%;
    visibility: hidden;

    @media all and (max-width: 600px) {
      width: 80%;
    }
  }

  &__description {
    color: $blue;
    margin: 20px 0;
  }
}

.about-us {
  padding: 160px;
  display: flex;
  gap: 60px;

  & > div {
    flex: 1;
    &:first-child {
      flex: 1.4;
    }
  }

  &__subtitle {
    color: $blue;
  }

  &__paragraph {
    margin: 10px 0;
    color: $grey;
    line-height: 1.6em;
  }

  &__experience {
    list-style: none;
    margin-top: 20px;
    padding-top: 80px;

    &--page {
      padding-top: 0;
    }

    li {
      padding-left: 30px;
      position: relative;
      margin-bottom: 10px;
      font-size: 1.2em;

      &::before {
        content: '';
        background-image: url('../images/check.svg');
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }

  &--background {
    background: #efefef;
    position: relative;

    & > div {
      z-index: 2;
    }

    &::before {
      content: '';
      background-image: url('../images/h.svg');
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      background-size: contain;
      top: 0;
      background-repeat: no-repeat;
      z-index: 1;

      @media all and (max-width: 600px) {
        background-size: cover;
      }
    }
  }

  &--page {
    padding: 120px;
    align-items: center;

    & > div {
      flex: 1 !important;
    }

    .about-us__subtitle {
      margin-bottom: 40px;
    }
  }

  @media all and (max-width: 980px) {
    padding: 160px 60px;
    gap: 30px;
  }

  @media all and (max-width: 820px) {
    flex-direction: column;

    &__experience {
      padding-top: 0;
    }
  }

  @media all and (max-width: 600px) {
    padding: 60px 30px;
  }
}

.services {
  background-color: $blue;
  padding: 100px 60px;
  border-top: solid 1px #dcdcdc;

  @media all and (max-width: 600px) {
    padding: 60px 30px;
  }

  h2 {
    text-align: center;
    color: #fff;
  }

  p {
    color: $light;
    text-align: center;
    margin-top: 15px;
    color: #fff;
  }

  &__grid {
    display: grid;
    margin-top: 60px;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

    @media all and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: solid 1px $light;
    border-radius: 13px;
    padding: 35px;
    box-sizing: border-box;
    background: #fff;
    text-decoration: none;

    &:hover {
      img {
        transform: scale(1.2);
      }
    }

    img {
      width: 45px;
      transition: all 0.3s ease;
    }

    &--mouse {
      img {
        width: 60px;
      }
    }

    h3 {
      font-weight: bold;
      color: $blue;
      width: 80%;
      margin-top: 20px;
      font-size: 1.1em;
    }
  }
}

.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--reverse {
    flex-direction: row-reverse;
  }

  &__cover {
    width: 50%;
    height: 700px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__details {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;

    h2 {
      color: $blue;
      font-size: 2em;
      text-align: center;
    }
  }

  &__button {
    margin-top: 60px;
    background-color: #148541 !important;
  }

  &__paragraph {
    font-size: 0.9em;
    margin-top: 10px;
  }

  @media all and (max-width: 820px) {
    flex-direction: column-reverse;

    &__cover {
      width: 100%;
      height: 300px;
    }

    &__details {
      width: 100%;
      padding: 100px 60px;
    }
  }
}

.services__details {
  .service {
    display: flex;
    height: 700px;
    position: relative;

    &::after {
      content: '';
      background-image: url('../images/h.svg');
      background-size: contain;
      width: 65px;
      height: 63px;
      position: absolute;
      z-index: 9;
      top: 20px;
      left: 20px;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      &::after {
        left: auto;
        right: 20px;
      }
    }

    @media all and (max-width: 980px) {
      height: 600px;
    }

    @media all and (max-width: 680px) {
      flex-direction: column;
      height: auto;

      &:nth-child(even) {
        flex-direction: column;
      }

      &:last-child {
        border-bottom: dashed 1px rgb(238, 238, 238);
      }
    }

    &__list {
      margin-top: 20px;
      font-size: 1.2em;
      list-style-position: inside;
      padding-left: 24px;
      box-sizing: border-box;

      li {
        margin-bottom: 10px;
      }
    }

    &__subtitle {
      color: $blue;
      margin-bottom: 20px;

      @media all and (max-width: 820px) {
        font-size: 2em;
      }
    }

    &__paragraph {
      &:nth-of-type(2) {
        color: $blue;
        font-size: 1em;
        margin-top: 15px;
      }
    }

    &__image {
      width: 50%;
      height: 100%;
      object-fit: cover;

      @media all and (max-width: 680px) {
        width: 100%;
        height: 300px;
      }
    }

    &__info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      padding: 100px 60px;
      box-sizing: border-box;

      @media all and (max-width: 980px) {
        padding: 60px 40px;
      }

      @media all and (max-width: 680px) {
        width: 100%;
      }
    }
  }
}

.footer {
  background-color: #1d2a4d;
  padding: 60px 160px;
  display: flex;
  gap: 40px;

  @media all and (max-width: 980px) {
    padding: 60px 30px;
    flex-direction: column;
  }

  &__column {
    flex: 1;

    &--full-width {
      flex: 2;
    }
  }

  &__logo {
    width: 300px;

    @media all and (max-width: 600px) {
      width: 100%;
    }
  }
  &__description {
    color: #fff;
    font-size: 1em;
    font-weight: 400;
    margin-top: 15px;
  }
  &__copyright {
    font-size: 0.8em;
    color: #fff;
    margin-top: 15px;
  }

  &__subtitle {
    font-size: 1.2em;
    color: #fff;
    margin-top: 30px;
  }

  &__links {
    margin-top: 20px;

    @media all and (max-width: 600px) {
      list-style-position: inside;
    }

    li {
      margin-bottom: 10px;
      color: #fff;

      &::marker {
        color: #fff; /* Change the bullet color */
      }

      a {
        text-decoration: none;
        color: #fff;
        transition: all 0.5s ease;

        &:hover {
          color: color(srgb 0.97 0.93 0.93 / 0.66);
        }
      }
    }
  }
}

.button {

  &--about-us {
    background: $blue;
    display: block;
    width: fit-content;
    margin-top: 20px;
    font-size: 1em;
  }

  &--floating {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: block;
    padding: 0;
    position: fixed;
    right: 15px;
    bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    &-phone {
      display: none;
      right: auto;
      left: 15px;

      @media all and (max-width: 600px) {
        display: flex;
      }
    }

    img {
      width: 30px;
    }
  }
}
